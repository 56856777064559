<template>
    <div>
        <section id="teams" class="doctors section-bg">
      <div class="container">

        <div class="section-title">
          <h2>TEAMS</h2>
          <p>This Our Team PT Biotech Farma. </p>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6 d-flex align-items-stretch" v-for="team in teamList" :key="team.id">
            <div class="member">
              <div class="member-img">
                <img :src="BaseIMG + '/assets/img/team/' + team.image" class="img-fluid" alt="">
                <div class="social">
                  <a href=""><i class="bi bi-twitter"></i></a>
                  <a href=""><i class="bi bi-facebook"></i></a>
                  <a href=""><i class="bi bi-instagram"></i></a>
                  <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>{{ team.nama }}</h4>
                <span>{{ team.jabatan }}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    </div>
</template>

<script>
  import { getTeam } from '@/api.js';
    export default {
        data() {
            return {
              BaseIMG: process.env.VUE_APP_IMG,
                teamList: []
            }
        },
        methods: {
          async fetchTeam() {
            try {
              const response = await getTeam();
              this.teamList = response.data.data;
            } catch (error) {
              console.error('Error fetching Team data:', error);
            }
          },
        },
        created() {
        this.fetchTeam();
      },
    }
</script>

<style lang="scss" scoped>

</style>