<template>
    <div>
       <topbar-nav></topbar-nav>
        <header-nav></header-nav>
       <carousel />
        <main id="main">
        <!-- ======= Featured Services Section ======= -->
        <section id="featured-services" class="featured-services">
        <div class="container">
            <div class="row">
            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div class="icon-box">
                <div class="icon"><i class="fas fa-heartbeat"></i></div>
                <h4 class="title"><a href="">ON-SITE PROGRAMS</a></h4>
                <p class="description">On-Site Managed Programs offer comprehensive solutions for managing and maintaining medical equipment in a healthcare facility</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div class="icon-box">
                <div class="icon"><i class="fas fa-pills"></i></div>
                <h4 class="title"><a href="">EQUIPMENT SERVICES</a></h4>
                <p class="description">Biotech Farma provides rental, sales and technical services for nearly all types of medical equipment</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div class="icon-box">
                <div class="icon"><i class="fas fa-thermometer"></i></div>
                <h4 class="title"><a href="">BETTER EQUIPPED</a></h4>
                <p class="description">No other company partners with healthcare professionals the way Biotech farma does</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div class="icon-box">
                <div class="icon"><i class="fas fa-dna"></i></div>
                <h4 class="title"><a href="">HOW WE SERVE CLIENTS</a></h4>
                <p class="description">Supply chain management, pharmacy and biomed rely on Biotech Farma to meet the demand</p>
                </div>
            </div>
            </div>
        </div>
        </section><!-- End Featured Services Section -->
        <section id="about" class="about">
          <about />
        </section><!-- End About Us Section -->
        <team />
         <!-- ======= Gallery Section ======= -->
         <section id="gallery" class="gallery">
          <swiper />
          </section>
        <!-- ======= Contact Section ======= -->
        <section id="contact" class="contact">
          <contact />
        </section><!-- End Contact Section -->

        </main><!-- End #main -->
        <footer-nav></footer-nav>
    </div>
</template>

<script>
import TopbarNav from '@/components/Topbar'
import HeaderNav from '@/components/Header'
import FooterNav from '@/components/Footer'
import Carousel from '@/components/Carousel'
import Swiper from '@/components/Swiper'
import Team from '@/components/Team'
import Contact from '@/components/Contact'
import About from '@/components/About'

    export default {
        components: {
            TopbarNav, HeaderNav, FooterNav, Carousel, Swiper, Team, Contact, About
        },
    }
</script>
