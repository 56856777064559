<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import './assets/biotech/vendor/fontawesome-free/css/all.min.css'
import './assets/biotech/vendor/animate.css/animate.min.css'
import './assets/biotech/vendor/bootstrap/css/bootstrap.min.css'
import './assets/biotech/vendor/bootstrap-icons/bootstrap-icons.css'
import './assets/biotech/vendor/boxicons/css/boxicons.min.css'
import './assets/biotech/vendor/glightbox/css/glightbox.min.css'
import './assets/biotech/css/style.css'
import './assets/biotech/vendor/swiper/swiper-bundle.min.css'

import './assets/biotech/vendor/purecounter/purecounter_vanilla.js'
import './assets/biotech/vendor/glightbox/js/glightbox.min.js'
import './assets/biotech/vendor/swiper/swiper-bundle.min.js'
import './assets/biotech/vendor/bootstrap/js/bootstrap.bundle.min.js'
import './assets/biotech/vendor/php-email-form/validate.js'

export default {
  name: 'App',
}
</script>
