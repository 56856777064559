import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL; 

export const getTeam = () => axios.get(`${API_URL}/team`);
export const getCategory = () => axios.get(`${API_URL}/category`);
export const getProduct = () => axios.get(`${API_URL}/product`);
export const getProductId = (id) => axios.get(`${API_URL}/product/${id}`);
export const getProductDetail = (code) => axios.get(`${API_URL}/product/detail/${code}`);
export const getContact = () => axios.get(`${API_URL}/contact`);
export const getSlider = () => axios.get(`${API_URL}/slider`);
export const getSwiper = () => axios.get(`${API_URL}/product-slide`);
export const getAbout = () => axios.get(`${API_URL}/about`);
// export const updateSiswa = (siswa) => axios.put(`${API_URL}/siswa/${siswa.id}`, siswa);
