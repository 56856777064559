<template>
    <div>
        <footer id="footer">
            <div class="footer-top">
            <div class="container">
                <div class="row">
                <div class="col-lg-3 col-md-6" v-for="item in dataContact" :key="item.id">
                    <div class="footer-info">
                    <h3>Biotech Farma</h3>
                    <p>
                        {{ item.alamat }}<br/><br/>
                        <strong>Phone:</strong> {{ item.telpon }}<br>
                        <strong>Email:</strong> {{ item.email }}<br>
                    </p>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 footer-links">
                    <h4>Useful Links</h4>
                    <ul>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">About us</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Products</a></li>
                    </ul>
                </div>

                <div class="col-lg-3 col-md-6 footer-links">
                    <h4>Our Product</h4>
                    <ul>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">X-Ray</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Incubator & Infant Warmer</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Phototeraphy System</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Operating Table</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Operating Light</a></li>
                    </ul>
                </div>
                <div class="col-lg-4 col-md-6 footer-links">
                    <h4>Social Media</h4>
                    <div class="social-links mt-3">
                    <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                    <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                    <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                    <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                    </div>
                </div>
                </div>
            </div>
            </div>

            <div class="container">
            <div class="copyright">
                &copy; Copyright <strong><span>Biotech</span></strong>. All Rights Reserved
            </div>
            <div class="credits">
            </div>
            </div>
        </footer>
    </div>
</template>
<script>
    import { getContact } from '@/api.js';
    export default {
        data() {
            return {
              BaseIMG: process.env.VUE_APP_IMG,
                dataContact: []
            }
        },
        methods: {
          async fetchContact() {
            try {
              const response = await getContact();
              this.dataContact = response.data.data;
            } catch (error) {
              console.error('Error fetching Contact data:', error);
            }
          },
        },
        created() {
        this.fetchContact();
      },
    }
</script>