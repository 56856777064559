<template>
    <div>
        <topbar-nav></topbar-nav>
        <header-nav></header-nav>
        <section id="services" class="services services head-body">
            <div class="container">
                <div class="section-title">
                    <h2>Category Product</h2>
                    <p>The following is the product category data in our data list.</p>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 icon-box" v-for="item in dataCategory" :key="item.id">
                        <div class="icon"><i class="fas fa-heartbeat"></i></div>
                        <h4 class="title"><a :href="'/product/' + item.id">{{ item.nama }}</a></h4>
                        <p class="description">{{ item.note }}</p>
                    </div>
                </div>
            </div>
            </section>
        <footer-nav></footer-nav>
    </div>
</template>

<script>
import TopbarNav from '@/components/Topbar'
import HeaderNav from '@/components/Header'
import FooterNav from '@/components/Footer'
import { getCategory } from '@/api.js';
    export default {
        components: {
            TopbarNav, HeaderNav, FooterNav
        },
        data() {
            return {
                dataCategory: []
            }
        },
        methods: {
          async fetchCategory() {
            try {
              const response = await getCategory();
              this.dataCategory = response.data.data;
            } catch (error) {
              console.error('Error fetching Category data:', error);
            }
          },
        },
        created() {
        this.fetchCategory();
      },
    }
</script>

<style>
.head-body {
    padding-top: 9rem;
}
</style>