<template>
    <div>
        <div class="container">
            <div class="section-title">
            <h2>About Us</h2>
            <p>Biotech Farma provides Indonesia with a wide range of Medical Mobility, Incontinence Supplies, Medical Equipment, Sleeping Aides & more. </p>
            </div>

            <div class="row" v-for="row in dataAbout" :key="row.id">
            <div class="col-lg-6">
              <div class="img-company">
                <template v-if="isImage(row.media)">
                  <img :src="BaseIMG + '/assets/media/' + row.media" class="img-fluid" alt="">
                </template>
                <template v-else>
                  <video controls class="size-video">
                    <source :src="BaseIMG + '/assets/media/' + row.media" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                </template>
              </div>
            </div>
                <div class="col-lg-6 pt-4 pt-lg-0 content">
                    <div v-html="row.content"></div>
                </div>
            </div>
            </div>
    </div>
</template>

<script>
    import { getAbout } from '@/api.js';
    export default {
        data() {
            return {
              BaseIMG: process.env.VUE_APP_IMG,
                dataAbout: []
            }
        },
        methods: {
          async fetchAbout() {
            try {
              const response = await getAbout();
              this.dataAbout = response.data.data;
            } catch (error) {
              console.error('Error fetching Contact data:', error);
            }
          },
          isImage(media) {
            return /\.(jpg|jpeg|png|gif)$/i.test(media);
          }
        },
        created() {
        this.fetchAbout();
      },
    }
</script>
<style>
.img-company {
  max-height: 17rem;
}
.size-video {
  width: 100%;
}
</style>
