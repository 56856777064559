import { createRouter, createWebHistory } from 'vue-router';
import Home from "@/pages/home.vue"
import Product from "@/pages/product.vue"
import Category from "@/pages/category.vue"
import ProductCategory from "@/pages/product_category.vue"
import ProductId from "@/pages/product_id.vue"

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
      { path: '/', component: Home },
      { path: '/product', component: Product },
      { path: '/category', component: Category },
      { path: '/product/:id', component: ProductCategory },
      { path: '/product/detail/:code', component: ProductId },
    ],
  });

router.beforeEach((to, from, next) => {
  console.log(`Navigating to: ${to.path}`);
  next();
});

export default router;
