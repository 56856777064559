<template>
    <div class="head-carousel">
        <carousel :items-to-show="1.0">
            <slide v-for="slide in dataSlide" :key="slide">
                <template v-if="isImage(slide.media)">
                    <img :src="BaseIMG + '/assets/img/slider/' + slide.media" :alt="slide.alt" class="img-slider" />
                </template>
                <template v-else>
                    <video controls class="img-slider">
                        <source :src="BaseIMG + '/assets/img/slider/' + slide.media" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </template>
            </slide>
            <template #addons>
            <navigation />
            <pagination />
            </template>
        </carousel>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { getSlider } from '@/api.js';
    export default {
        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
        },
        data() {
            return {
                BaseIMG: process.env.VUE_APP_IMG,
                dataSlide : []
            }
        },
        methods: {
          async fetchSlide() {
            try {
              const response = await getSlider();
              this.dataSlide = response.data.data;
            } catch (error) {
              console.error('Error fetching slide data:', error);
            }
          },
          isImage(media) {
            return /\.(jpg|jpeg|png|gif)$/i.test(media);
          }
        },
        created() {
        this.fetchSlide();
      },
    }
</script>

<style>
.carousel-item {
  width: 100%;
}
.head-carousel {
  padding-top: 0rem;
}
.img-slider {
  width: 100%;
  height: 75vh;
}
@media screen and (max-width: 768px) {
  .img-slider {
    height: 41vh;
  }
}
</style>
