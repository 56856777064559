<template>
    <div>
        <header id="header" class="fixed-top">
            <div class="container d-flex align-items-center mb-10">
            <a href="/" class="logo me-auto"><img src="@/assets/biotech/img/logos.png" alt=""></a>
            <nav id="navbar" class="navbar order-last order-lg-0">
                <ul>
                <li><a class="nav-link scrollto " href="/">Home</a></li>
                <li><a class="nav-link scrollto" href="/#about">About</a></li>
                <li><a class="nav-link scrollto" href="/category">Category</a></li>
                <li><a class="nav-link scrollto" href="/product">Products</a></li>
                <li><a class="nav-link scrollto" href="/#teams">Teams</a></li>
                <li><a class="nav-link scrollto" href="/#contact">Contact</a></li>
                </ul>
                <i class="bi bi-list mobile-nav-toggle"></i>
            </nav>
            </div>
        </header>
    </div>
</template>
<script>
export default {
    mounted() {
    const toggleButton = document.querySelector('.mobile-nav-toggle');
    toggleButton.addEventListener('click', () => {
        const navbar = document.getElementById('navbar');
        navbar.classList.toggle('navbar-mobile');
    });
  }
}
</script>

