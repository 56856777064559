<template>
    <div>
        <div class="container">
            <div class="section-title">
            <h2>Contact</h2>
            <p>PT Biotech Farma this is the complete address on google Map.</p>
            </div>
        </div>
        <div>
            <iframe style="border:0; width: 100%; height: 350px;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.665550834047!2d106.80856047526943!3d-6.17550936051496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f67dc2b94693%3A0x111bf91e0ab45c60!2sJl.%20Tanah%20Abang%20II%20No.82%2C%20RW.10%2C%20Cideng%2C%20Kecamatan%20Gambir%2C%20Kota%20Jakarta%20Pusat%2C%20Daerah%20Khusus%20Ibukota%20Jakarta%2010150!5e0!3m2!1sid!2sid!4v1721435161684!5m2!1sid!2sid" frameborder="0" allowfullscreen></iframe>
        </div>
        <div class="container" v-for="row in dataContact" :key="row.id">
            <div class="row mt-5">
            <div class="col-lg-6">
                <div class="row">
                <div class="col-md-12">
                    <div class="info-box" style="padding: 2rem 5rem;">
                    <i class="bx bx-map"></i>
                    <h3>Our Address</h3>
                    <p>{{ row.alamat }}</p>
                    </div>
                </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="row">
                <div class="col-md-6">
                    <div class="info-box mt-4">
                    <i class="bx bx-envelope"></i>
                    <h3>Email Us</h3>
                    <p>{{ row.email }}</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="info-box mt-4">
                    <i class="bx bx-phone-call"></i>
                    <h3>Call Us</h3>
                    <p>{{ row.telpon }}</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getContact } from '@/api.js';
    export default {
        data() {
            return {
              BaseIMG: process.env.VUE_APP_IMG,
                dataContact: []
            }
        },
        methods: {
          async fetchContact() {
            try {
              const response = await getContact();
              this.dataContact = response.data.data;
            } catch (error) {
              console.error('Error fetching Contact data:', error);
            }
          },
        },
        created() {
        this.fetchContact();
      },
    }
</script>

<style lang="scss" scoped>

</style>